<template>
  <div>
    <div id="map" />
    <v-container fluid>
      <div class="d-flex justify-end">
        <v-btn outlined color="darkblue" @click="showEditDialog = true">Ajouter un dispositif</v-btn>
      </div>

      <Table
        :headers="headers"
        :items="items"
        @onDelete="showDeleteDialog"
        @onEdit="openEditDialog"
        @onCopy="copyRtmpLink"
        :loading="this.isLoading"
        isDeviceTable
      />
    </v-container>

    <v-dialog v-model="showEditDialog" width="500">
      <v-card>
        <v-card-title>{{ form.newDevice ? 'Ajouter un' : 'Éditer le' }} dispositif</v-card-title>

        <v-card-text>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <v-form ref="form" v-model="isValid" lazy-validation>
            <v-text-field
              v-model="form.deviceName"
              outlined
              dense
              label="Nom du dispositif"
              class="pt-5"
              :rules="[rules.required, rules.minFour]"
              maxlength="16"
            />

            <v-select
              v-model="form.owner"
              :rules="[rules.required]"
              :items="users"
              :item-text="(item) => item.firstName + ' ' + item.lastName"
              item-value="id"
              label="Utilisateur"
              outlined
              dense
              :disabled="form.isDefault"
            />
            <v-select
              v-model="form.deviceType"
              :rules="[rules.required]"
              :items="['Drone', 'Body Cam', 'EyeVision Camera', 'Mobile Phone']"
              label="Type de dispositif"
              outlined
              dense
              @change="clearLatLong"
              :disabled="form.isDefault"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn color="secondary" text @click="onSubmitForm" :loading="isLoading"> Sauvegarder </v-btn>
          <v-btn color="error" text @click="showEditDialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCached } from '@mdi/js';
import Table from '@/components/Admin/Table';
import { generateValues } from '../../utils/stringGenerator';

export default {
  data: () => ({
    headers: [
      { class: 'caption darkblue--text', text: 'Nom du dispositif', value: 'deviceName' },
      { class: 'caption darkblue--text', text: 'Type de dispositif', value: 'deviceType' },
      { class: 'caption darkblue--text', text: 'Longitude', value: 'longitude' },
      { class: 'caption darkblue--text', text: 'Latitude', value: 'latitude' },
      { class: 'caption darkblue--text', text: 'Utilisateur', value: 'owner' },
      { class: 'caption darkblue--text', text: 'Lien RTMP', value: 'rtmpLink' },
      { text: '', value: 'actions', align: 'center' },
    ],
    showEditDialog: false,
    isValid: true,
    form: {
      deviceName: '',
      deviceType: '',
      owner: '',
      longitude: '',
      latitude: '',
      newDevice: true,
      isDefault: false,
    },
    rules: {
      required: (v) => !!v || 'Ce champ est requis',
      minFour: (v) => v.length >= 4 || 'Doit avoit au moins 4 caractères',
    },
    showMenu: false,
    service: null,
    placesService: null,
    autocompleteItems: null,
    loadIcon: mdiCached,
    disabled: false,
    errorMessage: null,
  }),
  components: {
    Table,
  },
  mounted() {
    if (!this.$store.state.devices.isInitialized) {
      this.$store.dispatch('devices/getDevices');
      this.$store.dispatch('devices/getDevicesForCount');
    }

    if (!this.$store.state.users.isInitialized) {
      this.$store.dispatch('users/getUsers');
      this.$store.dispatch('users/getOwnerList');
    }
  },
  computed: {
    items() {
      return this.$store.state.devices.devices;
    },
    isLoading() {
      return this.$store.state.devices.isLoading;
    },
    users() {
      return this.$store.state.users.ownerList;
    },
  },
  methods: {
    async showDeleteDialog(payload) {
      const confirm = await this.$root.$confirmDiaglog('Confirmation', 'Être-vous certain de vouloir effacer ce dispositif?');

      if (confirm) {
        await this.$store.dispatch('devices/deleteDevice', { payload });
      }
    },
    openEditDialog(item) {
      this.form = { ...item, newDevice: false };
      this.showEditDialog = true;
    },
    async onSubmitForm() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('devices/submitDevice', { payload: this.form });
        if (!this.$store.state.devices.error) {
          this.showEditDialog = false;
          this.errorMessage = null;
        } else {
          this.errorMessage = this.$store.state.devices.error;
        }
      }
    },
    clearLatLong() {
      this.form.longitude = '';
      this.form.latitude = '';
    },
    getLatLong() {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.form.longitude = pos.coords.longitude;
        this.form.latitude = pos.coords.latitude;
      });
    },
    generateDeviceName() {
      this.errorMessage = null;
      this.form.deviceName = generateValues();
    },
    generateUsername() {
      this.errorMessage = null;
      this.form.username = generateValues();
    },
    generatePassword() {
      this.form.password = generateValues();
    },
    copyRtmpLink(item) {
      const protocol = process.env.VUE_APP_PROTOCOL;
      const rtmpHostname = process.env.VUE_APP_RTMP_HOSTNAME;
      navigator.clipboard.writeText(`${protocol}://${rtmpHostname}/live/${item.id}`);
    },
  },
  watch: {
    showEditDialog(val) {
      if (!val) {
        this.form = {
          deviceName: '',
          username: '',
          password: '',
          deviceType: '',
          owner: '',
          longitude: '',
          latitude: '',
          newDevice: true,
          isDefault: false,
        };

        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>
